<template>
  <div class="interview">
    <!-- 待面试人员 -->
    <van-nav-bar :title="$t('toInterview.viewTxt1')"
                 left-text
                 left-arrow
                 @click-left="onClickLeft"
                 @click-right="onClickRight"
                 :fixed="true" />
    <div class="fixedH"
         style="height: 1.22667rem"></div>
    <div class="content">
      <my-calendar @outDate="getdate"
                   :yymmddArr="yymmdd" />
      <ul style="margin-top: 0.32rem">
        <li v-for="(item, index) in viewList"
            :key="index"
            @click="toViewInfo(item, index)">
          <div class="viewZp">
            <svg-icon style="width: 100%; height: 100%"
                      icon-class="default_avator" />
          </div>
          <div style="width: 100%; min-height: 2.25rem">
            <div class="row1">
              {{ item.empname }}
              <span v-if="item.iv_res == '面试通过'"
                    style="color: green">【{{ item.iv_res }}】</span>
              <span v-else-if="item.iv_res != '面试通过' && item.iv_res"
                    style="color: red">【{{ item.iv_res }}】</span>
            </div>
            <div class="row2">
              <span>{{ item.workyear }}{{ $t("toInterview.viewTxt2") }}</span>
              <span>{{ item.degree }}</span>
            </div>
            <div class="row3">
              <span>{{ $t("toInterview.viewTxt3") }}{{ item.staname }}</span>
            </div>
            <!-- <div class="row3">
              <span>面试时间：{{ item.iv_time }}</span>
            </div> -->
            <div class="row4">
              <div class="row4_left">
                <span>{{ item.telephone }}</span>
                <svg-icon icon-class="interview_tel"></svg-icon>
                <svg-icon icon-class="interview_wx"></svg-icon>
              </div>
              <div class="row4_right">
                <span class="span1">面试时间：</span>
                <span class="span2">{{ item.iv_time }}</span>
              </div>
            </div>
          </div>

          <div class="row5">
            <span @click.stop="mscp(item, index)"
                  :class="!item.stationid ? 'limitclick' : ''">{{ $t("toInterview.viewTxt4") }}</span>
            <span @click.stop="mspj(item, index)">{{
              $t("toInterview.viewTxt5")
            }}</span>
          </div>
        </li>
      </ul>
    </div>
    <van-dialog v-model="show"
                title=""
                :showConfirmButton="false">
      <van-icon name="cross"
                @click="closeEwm" />
      <div v-if="loadingCPqr"
           class="view_loading">
        <van-loading type="spinner"
                     color="#1989fa" />
      </div>
      <div v-else>
        <div class="ewm">
          <qrcode :text="url" />
        </div>
        <p>
          {{ $t("toInterview.viewTxt6") }}<br />
          {{ $t("toInterview.viewTxt7") }}
        </p>
        <van-button v-if="showCheckResBtn"
                    color="#2b8df0"
                    size="small"
                    style="margin-bottom:10px;"
                    @click="viewCPResults">查看测评结果</van-button>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { getHasIVDateList, getIvList, checkIvQtRes } from "@api/wxzp.js";
const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {};
import myCalendar from "./myCalendar";
import qrcode from "@components/qrcode";
import { Toast } from "vant";
export default {
  components: {
    myCalendar,
    qrcode,
  },
  data () {
    return {
      show: false,
      userInfo,
      yymmdd: [],
      viewList: [],
      url: "",
      yymm: "",
      nowselectData: "",
      setData: "",
      loadingCPqr: false,
      showCheckResBtn: false,
      viewIte: null
    };
  },
  methods: {
    closeEwm () {
      this.show = false;
    },
    mspj (ite, idx) {
      let queryObj = null;
      if (ite.iv_res)
      {
        queryObj = {
          autoid: ite.autoid,
          returnPath: "toInterview",
          pjid: ite.iv_autoid,
          iv_res: ite.iv_res,
          iv_pj: ite.iv_pj,
          remark: ite.remark,
          iv_outreason: ite.iv_outreason,
        };
      } else
      {
        queryObj = {
          autoid: ite.autoid,
          returnPath: "toInterview",
          pjid: ite.iv_autoid,
          iv_res: "",
          iv_pj: "",
          remark: "",
          iv_outreason: "",
        };
      }
      this.$router.push({
        path: "/viewEvaluate",
        query: queryObj,
      });
      window.sessionStorage.setItem("recordNowData", this.nowselectData);
    },
    // 面试测评
    mscp (ite, idx) {
      console.log(ite, "iteiteite");
      if (ite.stationid)
      {
        this.show = true;
        this.loadingCPqr = true
        this.viewIte = ite
        checkIvQtRes({
          rsid: ite.autoid
        }).then(res => {
          if (res.data[0].info == 'Y')
          {
            this.showCheckResBtn = true
          } else
          {
            this.showCheckResBtn = false
          }
          this.url =
            location.origin +
            "/scanInterviewEntry?stationid=" +
            ite.stationid +
            "&rsid=" +
            ite.autoid;
          this.loadingCPqr = false
        })
      } else
      {
        Toast(this.$t("toInterview.viewTxt8"));
      }
    },
    // 查看测评结果
    viewCPResults () {
      this.$router.push({
        path: "/interviewResults",
        query: {
          rsid: this.viewIte.autoid,
          fromPath: '/toInterview'
        },
      });
    },
    onClickLeft () {
      this.$router.push("/home");
    },
    onClickRight () { },
    toViewInfo (ite, idx) {
      this.$router.push({
        path: "/viewInfo",
        query: {
          autoid: ite.autoid,
          stationid: ite.stationid,
          footerType: "待入职",
          pjid: ite.iv_autoid,
          iv_res: ite.iv_res,
          iv_pj: ite.iv_pj,
          remark: ite.remark,
        },
      });
    },
    async getdate (d) {
      this.nowselectData = d;
      this.yymm = d.replace(/\//g, "").slice(0, 6);
      let res1 = await getHasIVDateList({
        empid: this.userInfo.empid,
        yymm: this.yymm,
      });
      this.yymmdd = res1.data;
      let res2 = await getIvList({
        empid: this.userInfo.empid,
        yymmdd: d,
      });
      if (res2.data.length > 0)
      {
        this.viewList = res2.data;
      } else
      {
        this.viewList = [];
      }
    },
  },
};
</script>

<style lang="less" scoped>
.interview {
  height: 100vh;
  overflow: hidden;
  .van-nav-bar {
    background: #2b8df0;

    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
  }
  .van-dialog {
    /deep/.van-dialog__content {
      position: relative;
      height: 100%;
      text-align: center;
      .view_loading {
        height: 5.52rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .ewm {
        width: 4.52rem;
        margin: 0 auto;
        padding-top: 1rem;
        min-height: 5.52rem;
        img {
          width: 4.52rem;
          height: 4.52rem;
        }
      }
      p {
        width: 100%;
        margin-top: 0.5rem;
      }
      .van-icon {
        width: 28px;
        height: 28px;
        position: absolute;
        right: 40px;
        top: 32px;
      }
    }
  }
  .content {
    height: calc(100% - 92px);
    overflow: auto;
    .van-calendar {
      margin-bottom: 24px;
    }
    ul {
      li {
        position: relative;
        padding: 20px 30px;
        padding-bottom: 0;
        // height: 378px;
        background: #fff;
        box-sizing: border-box;
        margin-bottom: 24px;
        .viewZp {
          position: absolute;
          top: 38px;
          right: 30px;
          width: 1.8rem;
          height: 1.8rem;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .row1 {
          height: 40px;
          line-height: 40px;
          font-size: 40px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          margin-bottom: 20px;
          line-height: 1;
        }
        .row2 {
          font-size: 28px;
          font-family: Source Han Sans CN;
          color: #666;
          margin-bottom: 20px;
          line-height: 1;
          span:first-child {
            padding-right: 14px;
            border-right: 1px solid #999;
          }
          span:last-child {
            margin-left: 10px;
          }
        }
        .row3 {
          font-size: 28px;
          font-family: Source Han Sans CN;
          color: #999;
          margin-bottom: 20px;
        }
        .row4 {
          display: flex;
          justify-content: space-between;
          font-size: 32px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          margin-bottom: 30px;
          line-height: 1;
          .row4_left {
            color: #2b8df0;
            span {
              margin-right: 10px;
            }
            .svg-icon {
              font-size: 36px;
              margin: 0 5px;
            }
          }
          .row4_right {
            font-size: 32px;
            .span1 {
            }
            .span2 {
              color: #2b8df0;
            }
          }
          // .row4_right {
          //   font-size: 36px;
          //   font-family: Source Han Sans CN;
          //   font-weight: 500;
          // }
        }
        .row5 {
          display: flex;
          border-top: 1px solid #ccc;
          padding: 15px;
          span {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 36px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #2b8df0;
          }
          span:first-child {
            border-right: 1px solid #cccccc;
          }
        }
      }
    }
  }
}
.limitclick {
  color: #ccc !important;
}
</style>
